import React, { useState } from "react"
import "./ItemOpener.css"
import openerVideo from "../../videoes/openerVideo.mp4"
import {
  IoIosArrowForward,
  IoIosBook,
  IoMdArrowRoundBack,
  IoMdCode,
  IoMdFiling,
  IoMdGlobe,
  IoMdList,
  IoMdLock,
} from "react-icons/io"
import useWidth from "../../globalStyles/useWidth"

interface ItemData {
  name: string
  logo: React.ReactNode
  color: string;
  description: string
}

const items: ItemData[] = [
  {
    name: "Text",
    logo: <IoMdFiling />,
    color: "#3BFF5C",
    description:
      "Copies the text into your clipboard. Great for storing text you often write, like your name, email, etc. Paste it with ctrl/cmd+v or right-click and select paste.",
  },
  {
    name: "URL - web address",
    logo: <IoMdGlobe />,
    color: "#FF3B3B",
    description:
      "Opens the web address in your default browser. A great alternative to the browser-bookmarks.",
  },
  {
    name: "Note",
    logo: <IoIosBook />,
    color: "#FFa73B",
    description:
      "Shows your note in a new window. Great for small and large notes with fast access. Close the newly created window with the escape key.",
  },
  {
    name: "Password",
    logo: <IoMdLock />,
    color: "#E53BFF",
    description:
      "Copies either the username, password, or secret note to the clipboard. Great for unique passwords to every site you access.",
  },
  {
    name: "Group",
    logo: <IoMdList />,
    color: "#FFFC3B",
    description:
      "Opens all the items in the group. Great for opening multiple links for work, or to have different modes.",
  },
  {
    name: "Shell",
    logo: <IoIosArrowForward />,
    color: "#3BF8FF",
    description:
      "ADVANCED FEATURE: Runs a shell command with/without output dialog.",
  },
  {
    name: "Code",
    logo: <IoMdCode />,
    color: "#2F80ED",
    description:
      "ADVANCED FEATURE: Copies the code into your clipboard. Great for storing code-snippets and code you write often. It supports syntax highlighting in the editor.",
  },
]

export default function ItemOpener() {
  const [selectedItem, setSelectedItem] = useState<ItemData>(items[0])
  const width = useWidth()

  const close = () => {
    window.location.href = "/"
  }

  const selectChange = (e: any) => {
    const name = e.target.value
    const item = items.find(i => i.name === name);
    setSelectedItem(item)
  }

  return (
    <div className="itemOpener">
      <button className="secondary btn closeBtn" onClick={close}>
        <IoMdArrowRoundBack /> Go Back
      </button>
      <div className="body">
        <h1>The golden feature of SevenClip</h1>
        <h2>Available on the desktop version</h2>

        <div className="topDiv">
          <video width="355" height="200" autoPlay loop>
            <source src={openerVideo} type="video/mp4" />
          </video>

          <div>
            <h3>3 simple steps</h3>
            <ul>
              <li>Click a shortcut</li>
              <li>Search</li>
              <li>Click enter</li>
            </ul>
          </div>
        </div>

        <div className="visual">
          <div className="leftSide">
            {width > 800 ? (
              <ul>
                {items.map(item => (
                  <li
                  style={{ color: item.color, fontSize: "var(--font-size-700)" }}
                    onClick={() => {
                      setSelectedItem(item)
                    }}
                    className={
                      selectedItem.name === item.name ? "selected" : ""
                    }
                    key={item.name}
                  >
                    {item.logo}
                    <p style={{ color: "#000", fontSize: "var(--font-size-500)" }}>{item.name}</p>
                  </li>
                ))}
              </ul>
            ) : (
              <select value={selectedItem.name} onChange={selectChange}>
                {items.map(item => (
                  <option value={item.name}>
                    {item.name}
                  </option>
                ))}
              </select>
            )}
          </div>

          <div className="rightSide">
            <p>{selectedItem.description}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
