import React, { useEffect } from "react"

var canUseDOM = !!(
  typeof window !== "undefined" &&
  window.document &&
  window.document.createElement
)

export default (): number => {
  const [width, setWidth] = React.useState<number>(
    canUseDOM ? window.innerWidth : 1000
  )

  function handleSizeChange(): void {
    return setWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener("resize", handleSizeChange)

    return () => {
      window.removeEventListener("resize", handleSizeChange)
    }
  }, [width])

  return width
}
